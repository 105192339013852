/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { supportRequest } from 'redux/reducers/download/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { IPopUp } from '../types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';

const RequestNewMemberPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [counterStateCode, setStateCounterCode] = useState('US');

	const [phonePrefix, setPhonePrefix] = useState<string>('+1');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');

	const onChange = (value: string, counterCode: string) => {
		setPhonePrefix(value);
		setStateCounterCode(counterCode);
	};

	const initialValues = {
		email: '',
		first_name: firstName,
		last_name: lastName,
		phone: phoneNumber,
		message: '',
		selectedOptions: [], // Новое поле для чекбоксов
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('Email is required')
			.email('Invalid email format')
			.max(60, 'Email must be at most 60 characters'),
		first_name: yup
			.string()
			.min(1, 'First name must be at least 1 character')
			.max(50, 'First name must be at most 50 characters')
			.required('First name is required'),
		last_name: yup
			.string()
			.min(1, 'Last name must be at least 1 character')
			.max(50, 'Last name must be at most 50 characters')
			.required('Last name is required'),
		phone: yup
			.string()
			.required('Phone number is required')
			.max(20, 'Phone number must be at most 20 digits')
			.matches(/^\d{9,20}$/, 'Phone number is invalid'),
		message: yup
			.string()
			.required('Message is required')
			.min(2, 'Message must be at least 2 characters')
			.max(255, 'Message must be at most 255 characters'),
		// eslint-disable-next-line react/forbid-prop-types
		selectedOptions: yup.array(), // Валидация для чекбоксов (можно добавить min, если нужно)
	});

	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					// history.push('/');
				}}
			>
				<div className="modal">
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header">
							<p className="popup-header__title">Request new member</p>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								const params: any = {
									apiParams: {
										first_name: obj.first_name,
										last_name: obj.last_name,
										email: obj.email,
										phone: obj.phone,
										message: obj.message,
										selectedOptions: obj.selectedOptions, // Передаем выбранные опции
									},
								};

								dispatch(supportRequest(params));
								setSubmitting(false);
								closeModal();
								resetForm();
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ isSubmitting, isValid, dirty, setFieldValue }) => (
								<Form className="form">
									<div className="popup-body">
										<Field
											title="First Name"
											type="text"
											placeholder="First Name"
											name="first_name"
											required
											component={Input}
										/>
										<Field
											title="Last Name"
											type="text"
											placeholder="Last Name"
											name="last_name"
											required
											component={Input}
										/>
										<Field
											title="Email Address"
											type="email"
											placeholder="Email Address"
											name="email"
											required
											component={Input}
										/>
										<p className="input__name">Phone Number</p>
										<PhoneInputField
											counterStateCode={counterStateCode}
											onChange={onChange}
											value={phonePrefix}
											enableSearch
											dropdownClass="phone-dropdown"
											searchClass="searchfield"
										>
											<Field
												type="tel"
												placeholder="Phone Number"
												name="phone"
												required
												component={Input}
												setValue={setFieldValue}
											/>
										</PhoneInputField>
										<div role="group" aria-labelledby="checkbox-group">
											<p className="input__name">Role</p>
											<div className="checkbox">
												<label>
													<Field type="checkbox" name="selectedOptions" value="Viewer" />
													<span className="custom-checkbox" />
													Viewer
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Trader" />
													<span className="custom-checkbox" />
													Trader
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Initiator" />
													<span className="custom-checkbox" />
													Initiator
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Approver" />
													<span className="custom-checkbox" />
													Approver
												</label>
											</div>
										</div>
										<Field
											title="Message"
											type="textarea"
											placeholder="How can we help?"
											name="message"
											required
											component={Input}
											className="mb-20"
										/>
									</div>
									<div className="popup-footer">
										<div className="popup-submit mt-0">
											<button type="submit" className="btn btn-primary btn--full">
												Send
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default RequestNewMemberPopUp;
