import React, { FC } from 'react';
import Auth from 'layouts/Auth';
import WalletSideBar from '../../../components/Wallets/WalletSideBar/WalletSideBar';
import YourAccountsMain from '../../../components/YourAccountsMain/YourAccountsMain';

// ==========================================:
const YourAccountsMainPage: FC = () => {
	return (
		<Auth title="Security settings">
			<section className="profile-section">
				<div className="page-wrap">
					<WalletSideBar />
					<YourAccountsMain />
				</div>
			</section>
		</Auth>
	);
};
export default YourAccountsMainPage;
