import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getTopPairRequest, getTradeHistoryRequest } from 'redux/reducers/transactions/reducer';
import {
	getTopPair,
	getTradeHistory,
	getTradeHistoryTotal,
} from 'redux/reducers/transactions/selectors';

import TradeForm from 'components/Trade/TradeForm/TradeForm';
import MainTradePair from 'components/Trade/MainTradePair/MainTradePair';
import TradeHistoryWithoutPagination from 'components/TransactionHistory/TradeHistoryWithoutPagination/TradeHistoryWithoutPagination';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { roundingNumber } from 'services/utils/roundingNumber';
import {
	initialTradeValues,
	updateCanDispatchTimer,
	updateLayers,
} from 'redux/reducers/trade/reducer';
import { ITradeStore } from 'redux/reducers/trade/types';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { getCurrencyData } from '../../redux/reducers/currency/selectors';
import { navList } from '../../routes/routesList';
import WalletBalances from './WalletBalances/WalletBalances';
import { ILocalFilter } from '../TransactionsTrade/Trade/types';
import { IGetTransactionHistoryRequestPayload } from '../../redux/reducers/transactions/types';
import TableHeader from '../TransactionsTrade/Trade/TableHeader/TableHeader';
import TableBody from '../TransactionsTrade/Trade/TableBody/TableBody';
import { getPopUp } from '../../redux/reducers/popUp/selectors';
import { getCanDispatchTimer } from '../../redux/reducers/trade/selectors';

const Trade = () => {
	const dispatch = useDispatch();
	const topPairs = useSelector(getTopPair);
	const mainCurrency = useSelector(getCurrencyData);
	const currency = mainCurrency.filter((el) => el.exchangeable);
	const itemList = useSelector(getTradeHistory)?.data;

	const [filters, setFilters] = useState({
		date_from: undefined,
		date_to: undefined,
		sell: undefined,
		buy: undefined,
		uuid: undefined,
	});
	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});

	const changeFilter = (changeFilterValue: string) => {
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};
	const [perPage, setPerPage] = useState<number | undefined>(5);
	const total = useSelector(getTradeHistoryTotal);
	const [currentPage, setCurrentPage] = useState(1);
	const currentPopup = useSelector(getPopUp);

	/* Test layers */
	const [layers, setLayers] = useState<any | null>({
		USTEUR: {
			name: 'USTEUR',
			levels: {
				buy: {
					'100000': '0.92053',
					'250000': '0.92053',
					'500000': '0.92053',
					'1000000': '0.92053',
					'3000000': '0.92059',
				},
				sell: {
					'100000': '0.91849',
					'250000': '0.91847',
					'500000': '0.91842',
					'1000000': '0.91833',
					'3000000': '0.9181',
				},
			},
		},
	});
	/* End Test layers */

	const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_SOCKET_URL as string);
	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
	const canDispatchTimer = useSelector(getCanDispatchTimer);

	useEffect(() => {
		if (lastMessage?.data && canDispatchTimer) {
			if (currentPopup.popUpText === 'successTrade') return;
			dispatch(updateLayers(JSON.parse(lastMessage?.data)));
			// dispatch(updateLayers(layers));
			dispatch(updateCanDispatchTimer(false));
			setTimeout(() => {
				dispatch(updateCanDispatchTimer(true));
			}, 10000);
		}
	}, [currentPopup.popUpText, dispatch, lastMessage, canDispatchTimer]);

	// const connectionStatus = {
	// 	[ReadyState.CONNECTING]: 'Connecting',
	// 	[ReadyState.OPEN]: 'Open',
	// 	[ReadyState.CLOSING]: 'Closing',
	// 	[ReadyState.CLOSED]: 'Closed',
	// 	[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	// }[readyState];
	// console.log(ReadyState);

	useEffect(() => {
		const math = Math.round(total / Number(perPage));
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: math + 1 >= currentPage ? currentPage : 1,
				per_page: perPage === undefined ? 100000000 : perPage,
				...filters,
			},
		};
		if (params.apiParams?.current_page === 1) {
			setCurrentPage(1);
		}
		dispatch(getTradeHistoryRequest(params));
	}, [dispatch, currentPage, perPage, filters, total]);

	useEffect(() => {
		const hasBtc = currency.some((item) => item?.code === 'btc');
		const hasEur = currency.some((item) => item?.code === 'eur');
		const rawAsset = localStorage.getItem('asset');
		let retrievedAsset: { asset_id?: string; asset_code?: string; type?: string } | null = null;
		let cryptoCurrency = currency.find((item) => item.type === 'crypto');
		let fiatCurrency = currency.find((item) => item.type === 'fiat');
		if (rawAsset) {
			retrievedAsset = JSON.parse(rawAsset);
		}
		let payload: ITradeStore;
		if (!hasBtc || !hasEur) {
			if (currency[0]?.id !== undefined && currency[1]?.id !== undefined) {
				if (!cryptoCurrency) {
					cryptoCurrency = currency.find((item) => item.type === 'token');
				}

				if (!fiatCurrency) {
					fiatCurrency = currency.find((item) => item.type === 'token');
				}

				if (
					cryptoCurrency?.id !== undefined &&
					fiatCurrency?.id !== undefined &&
					retrievedAsset !== null
				) {
					const funcTypeToAssetId = () => {
						if (retrievedAsset?.type === 'fiat' || retrievedAsset?.type === 'fiat') {
							return String(cryptoCurrency?.id);
						}
						return String(fiatCurrency?.id);
					};
					const funcTypeToAssetCode = () => {
						if (retrievedAsset?.type === 'fiat' || retrievedAsset?.type === 'fiat') {
							return String(cryptoCurrency?.code);
						}
						return String(fiatCurrency?.code);
					};
					payload = {
						from_asset_id:
							retrievedAsset && String(retrievedAsset.asset_id)
								? String(retrievedAsset.asset_id)
								: String(cryptoCurrency.id),
						from_asset_code:
							retrievedAsset && retrievedAsset.asset_code
								? retrievedAsset.asset_code
								: cryptoCurrency.code,
						from_asset_count: '',
						to_asset_id: funcTypeToAssetId(),
						to_asset_code: funcTypeToAssetCode(),
						to_asset_count: '',
					};

					dispatch(initialTradeValues(payload));
				} else {
					payload = {
						from_asset_id: String(cryptoCurrency?.id),
						from_asset_code: String(cryptoCurrency?.code),
						from_asset_count: '',
						to_asset_id: String(fiatCurrency?.id),
						to_asset_code: String(fiatCurrency?.code),
						to_asset_count: '',
					};
					dispatch(initialTradeValues(payload));
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency]);

	useEffect(() => {
		const rawAsset = localStorage.getItem('asset');
		const cryptoCurrency = currency.find((item) => item.type === 'crypto');
		let retrievedAsset: { asset_id?: string; asset_code?: string; type?: string } | null = null;
		if (rawAsset) {
			retrievedAsset = JSON.parse(rawAsset);
		}
		const funcTypeFromAssetId = () => {
			if (
				retrievedAsset &&
				String(
					retrievedAsset.asset_id &&
						(retrievedAsset.type === 'crypto' || retrievedAsset.type === 'token'),
				)
			) {
				return retrievedAsset?.asset_id || '1';
			}
			return retrievedAsset?.asset_id || '1';
		};
		const funcTypeFromAssetCode = () => {
			if (
				retrievedAsset &&
				retrievedAsset.asset_code &&
				(retrievedAsset.type === 'crypto' || retrievedAsset.type === 'token')
			) {
				return retrievedAsset.asset_code || 'btc';
			}
			return retrievedAsset?.asset_code || 'btc';
		};
		const funcTypeToAssetId = () => {
			if (
				retrievedAsset &&
				String(retrievedAsset.asset_id) &&
				(retrievedAsset.type === 'crypto' || retrievedAsset.type === 'token')
			) {
				return 10;
			}
			if (retrievedAsset && String(retrievedAsset.asset_id) && retrievedAsset.type === 'fiat') {
				return cryptoCurrency?.id;
			}
			return 10;
		};
		const funcTypeToAssetCode = () => {
			if (
				retrievedAsset &&
				retrievedAsset.asset_code &&
				(retrievedAsset.type === 'crypto' || retrievedAsset.type === 'token')
			) {
				return 'eur';
			}
			if (retrievedAsset && retrievedAsset.asset_code && retrievedAsset.type === 'fiat') {
				return cryptoCurrency?.code;
			}
			return 'eur';
		};
		const payload = {
			from_asset_id: String(funcTypeFromAssetId()),
			from_asset_code: String(funcTypeFromAssetCode()),
			from_asset_count: '',
			to_asset_id: String(funcTypeToAssetId()),
			to_asset_code: String(funcTypeToAssetCode()),
			to_asset_count: '',
		};
		dispatch(initialTradeValues(payload));
		dispatch(getTopPairRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="profile-section">
			<div className="page-wrap">
				<WalletSideBar />
				<motion.div
					className="profile-content"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { duration: 0.4 } }}
					exit={{ opacity: 0, transition: { duration: 0.4 } }}
				>
					<section className="instant-trade-section">
						<div className="container container--full-width">
							<div className="wallet-content-header">
								<div className="wallet-content-header__title">
									<p>Trade</p>
								</div>
								<div className="wallet-content-header__buttons flex flex-e flex-m">
									<Link to={navList.trade.path} className="btn btn-primary">
										Trade
									</Link>
									<Link
										to={navList.depositFiat.path}
										className="btn btn-primary btn-primary--blue300"
									>
										Deposit
									</Link>
									<Link
										to={navList.withdrawFiat.path}
										className="btn btn-primary btn-primary--blue100"
									>
										Withdraw
									</Link>
								</div>
							</div>

							<div className="trade__row">
								<div className="instant-trade">
									<p className="trade__box-heading">Trade</p>
									<div className="instant-trade__box">
										<MainTradePair />
										<TradeForm />
									</div>
								</div>

								<div>
									<p className="trade__box-heading">Wallet Balances</p>
									<WalletBalances />
								</div>
							</div>
						</div>
					</section>

					{/* <section className="instant-coint-list-section">
						<div className="container">
							<div className="main-coint-list main-coint-list--mt-0 main-coint-list--gap-36">
								{!!topPairs?.length &&
									topPairs.map(({ change24h, last_price, asset }) => (
										<div key={asset.id} className="main-coint-list-item">
											<div className="main-coint-list-item__line">
												<span className="coin">
													<span className="coin__icon">
														<img src={asset.img_path} alt={asset.code} />
													</span>
													<span className="coin__text element---uppercase coin__text---bold">
														{asset.code}
													</span>
												</span>
												<div className="main-coint-list-item__price">
													<p>{roundingNumber(last_price, 'EUR')} EUR</p>
												</div>
											</div>
											<div className="main-coint-list-item__line">
												<div className="main-coint-list-item__change">
													<p>24 Hours Change</p>
												</div>
												<div
													className={`main-coint-list-item__change main-coint-list-item__change--ta-r ${
														change24h.toString().includes('-') ? 'red' : 'green'
													}`}
												>
													<p>{change24h} %</p>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</section> */}

					<section className="instant-trade-history-section">
						{/* <div className="page-title page-title--ta-c">
								<p>Trade History</p>
							</div> */}
						{/* <TradeHistoryWithoutPagination /> */}

						<div className="instant-trade-history-section__title-row">
							<div className="instant-trade-history-section__title">Trade History</div>
							<Link to="history/trade" className="btn--arrow">
								View History
							</Link>
						</div>

						<div className="table-block table-block--mt-0">
							<div className="table-wrapper">
								<div className="table table--transaction-history-trade">
									<TableHeader localFilter={localFilter} changeFilter={changeFilter} />
									<TableBody localFilter={localFilter} itemList={itemList} />
								</div>
							</div>
						</div>
					</section>
				</motion.div>
			</div>
		</section>
	);
};
export default Trade;
