import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeInfo } from 'redux/reducers/transactions/selectors';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { changeAsset, changeAssetCount } from 'redux/reducers/trade/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPopUp } from '../types';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

const SuccessTrade: FC<IPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const trade = useSelector(getTradeData);
	const tradeInfo = useSelector(getTradeInfo);

	const closeHandler = () => {
		// dispatch(tradeInitState());
		// dispatch(tradeInfoInitState());
		// dispatch(getTopPairRequest());
		dispatch(getWalletsRequest());
		// dispatch(
		// 	getTradeExchangeRateRequest({
		// 		from_asset_id: '1',
		// 		to_asset_id: '10',
		// 	}),
		// );
		dispatch(changeAsset({}));
		dispatch(
			changeAssetCount({
				type: 'to',
				asset_count: String(0),
			}),
		);
		closeModal();
	};

	// useEffect(() => {
	// 	const payload = {
	// 		asset_id: tradeInfo?.from_asset_id,
	// 		type: 'trade',
	// 		amount: trade?.from_asset_count,
	// 	};
	// 	dispatch(feeRequest(payload));
	// }, [dispatch, trade?.from_asset_count, tradeInfo?.from_asset_id]);

	const viewTransactionsHandler = () => {
		history.push('/history/trade');
		closeModal();
	};

	const percentfee = tradeInfo?.trade_fee_percent
		? (Number(trade.from_asset_count) * Number(tradeInfo?.trade_fee_percent)) / 100
		: 0;
	const mainFee =
		percentfee && tradeInfo && percentfee > Number(tradeInfo?.trade_fee_fixed)
			? percentfee
			: tradeInfo?.trade_fee_fixed || 0;

	const receivWithoutFee = Number(tradeInfo?.price) * (Number(trade.from_asset_count) - mainFee);

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeHandler}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={closeHandler} />
				<p className="popup-header__title">Trade Successful</p>
				<div className="popup-icon popup-icon">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-header">
					<div className="popup-text popup-text--mt-24 popup-text--mb-8">
						<p>You Receive</p>
					</div>

					<p className="popup-header__title success">
						{/* {popupData.data?.data.receive}{' '}
						<span className="element---uppercase">{popupData.data?.data.receiveCode}</span> */}
						{/* {roundingNumber(receivWithoutFee, trade?.to_asset_code || '')}{' '} */}
						{roundingNumber(tradeInfo?.amount, trade?.to_asset_code || '')}{' '}
						<span className="element---uppercase">{trade?.to_asset_code}</span>
					</p>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>You Sell:</p>
							<span>
								{/* {popupData.data?.data.sell}{' '}
								<span className="element---uppercase">{popupData.data?.data.sellCode}</span> */}
								{/* {roundingNumber(trade.from_asset_count || '', trade?.from_asset_code || '')}{' '} */}
								{tradeInfo?.quantity &&
									trade?.from_asset_code &&
									roundingNumber(Number(tradeInfo?.quantity), trade?.from_asset_code)}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>You Receive:</p>
							<span>
								{/* {popupData.data?.data.receive}{' '}
								<span className="element---uppercase">{popupData.data?.data.receiveCode}</span> */}
								{/* {roundingNumber(receivWithoutFee, trade?.to_asset_code || '')}{' '} */}
								{roundingNumber(tradeInfo?.amount, trade?.to_asset_code || '')}{' '}
								<span className="element---uppercase">{trade?.to_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Price:</p>
							<span>
								{/* 1 <span className="element---uppercase">{popupData.data?.data.priceOneCode}</span> ={' '}
								{popupData.data?.data.price}
								<span className="element---uppercase"> {popupData.data?.data.priceCode}</span> */}
								1 <span className="element---uppercase">{oneText}</span> ={' '}
								{/* {roundingNumber(tradeInfo?.price_b2c2 || 0, twoText || '')} */}
								{toFixedNumber(tradeInfo?.price_b2c2 || 0, 'crypto', undefined, 7)}
								<span className="element---uppercase"> {twoText}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Trade Fee:</p>
							<span>
								{/* {popupData.data?.data.fee}{' '}
								<span className="element---uppercase">{popupData.data?.data.feeCode}</span> */}
								{/* {roundingNumber(Number(fee), trade?.from_asset_code || '')}{' '} */}
								{roundingNumber(Number(tradeInfo?.fee), trade?.from_asset_code || '')}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
						{/* <div className="successful-info__item">
							<p>Spread Fee:</p>
							<span>
								{popupData.data?.data.spreadFee}
								<span className="element---uppercase">{popupData.data?.data.spreadFeeCode}</span>
							</span>
						</div> */}
					</div>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={viewTransactionsHandler}
							className="button button--full-width"
						>
							Trade History
						</button>
						<button type="button" onClick={closeHandler} className="btn btn-danger">
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessTrade;
