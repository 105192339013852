import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDetailedViewStore, IDetailedViewTradeDetails, IFileDownloadPayload } from './types';
import { ICreateTradeDetailsRequest } from '../../../services/api/detailedView/types';

// TODO: Add proper types
export const initialState: IDetailedViewStore = {
	loading: false,
	tradeDetails: null,
	fileLoading: false,
};

const detailedView = createSlice({
	name: '@@detailedView',
	initialState,
	reducers: {
		hideLoading: (state) => {
			const newState = state;
			newState.loading = false;
		},

		getTradeDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getTradeDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewTradeDetails>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		createTradeDetailsRequest: (state, { payload }: PayloadAction<ICreateTradeDetailsRequest>) => {
			const newState = state;
			newState.loading = true;
		},
		createTradeDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewTradeDetails>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		createFiatDetailsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.loading = true;
		},
		createFiatDetailsSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},

		createCryptoDetailsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.loading = true;
		},
		createCryptoDetailsSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},

		downloadCryptoFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},

		downloadFiatFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},
		downloadTradeFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},
	},
});

export default detailedView.reducer;

export const {
	hideLoading,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	downloadCryptoFileRequest,
	downloadFiatFileRequest,
	downloadTradeFileRequest,
} = detailedView.actions;
