import React, { useEffect, useState } from 'react';
import Dashboard from 'layouts/Dashboard';
import loginBannerImage from 'assets/dist/img/login-banner@2x-min.png';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Item from './Item/Item';
import IconSvg from '../../ui/Svg/IconSvg';

const LoginWorkspace = () => {
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);

	useEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		const rect = loginBannerRect.left - bodyRect.left;
		setBannerPositionLeft(rect);
	}, []);

	const data = [
		{ id: 1, name: 'Cody Fisher' },
		{ id: 2, name: 'Praise Network LTD' },
		{ id: 3, name: 'Northbank Corp' },
	];

	const determineColor = (id: number) => {
		switch (id % 3) {
			case 0:
				return 'blue';
			case 1:
				return 'orange';
			case 2:
				return 'purple';
			default:
				return 'grey'; // default color if needed
		}
	};

	return (
		<>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
			</Helmet>
			<Dashboard>
				<section className="login-section">
					<div className="container">
						<div className="login workspace">
							<Link to="/login" className="wallet-operations-header__back">
								<IconSvg name="arrow-right" w="24" h="24" />
							</Link>
							<div className="login-form">
								<div className="login-form__title">
									<p>Workspace</p>
									<span>Select account you want to use</span>
								</div>
								<div className="workspace-block">
									{data?.map((item) => (
										<Item
											key={item.id}
											id={item.id}
											name={item.name}
											color={determineColor(item.id)}
										/>
									))}
								</div>
							</div>
						</div>
						<div className="login-banner" id="login_banner">
							<img src={loginBannerImage} alt="" />
							<p className="login-banner__text">
								Multi-currency accounts, foreign exchange and
								<br /> payments that streamline your global operations
							</p>
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};

export default LoginWorkspace;
