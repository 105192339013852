/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Popup from 'reactjs-popup';
import Input from 'ui/Formik/Input';
import { useDispatch, useSelector } from 'react-redux';
import { IBankAccountRequestPayload } from 'redux/reducers/bankAccounts/types';
import { createBankAccountRequest } from 'redux/reducers/bankAccounts/reducer';
import CountriesSelect from 'ui/Formik/Select/CountriesSelect';
import InputPattern from 'ui/Formik/Input/inputPattern';
import { getNameList } from 'country-list';
import { IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { getFiatCurrencyData } from '../../../redux/reducers/currency/selectors';
import BankCurrencySelect from '../../../ui/Formik/Select/BankCurrencySelect';
import { capitalizeWords } from '../../../services/utils/strings';

export interface IBankAccountForm {
	id: number | null;
	closeForm: () => void;
}

export interface ICountry {
	value: string;
	label: string;
}

interface ICurrency {
	value: string;
	label: string;
	name: string;
	id: number | string;
}

const AddBankAccount: FC<IPropsPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const [countries, setCountries] = useState<[] | ICountry[]>([]);
	const [currencies, setCurrencies] = useState<[] | ICurrency[]>([]);
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>({
		value: '',
		label: '',
		name: '',
		id: '',
	});
	const [resetCustomSelect, setResetCustomSelect] = useState(false);

	useEffect(() => {
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			}); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useEffect(() => {
		const newArr: ICurrency[] = [];
		fiatCurrencyData.forEach((item) => {
			newArr.push({
				value: item.name,
				label: item.code,
				name: item.name,
				id: item.id,
			});
		});
		setCurrencies(newArr);
	}, [fiatCurrencyData]);

	const initialValues = {
		bank_nickname: '',
		beneficiary_name: '',
		beneficiary_address: '',
		beneficiary_country: '',
		bank_currency: '',
		bank_name: '',
		bank_address: '',
		bank_country: '',
		swift_bic: '',
		account_number: '',
		note: '',
	};

	const [initialInputValues, setInitialInputValues] =
		useState<IBankAccountRequestPayload>(initialValues);

	const validationSchema = yup.object().shape({
		bank_nickname: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_nickname.required)
			.min(1, notificationsInfoFields.validationMessages.bank_nickname.min)
			.max(160, notificationsInfoFields.validationMessages.bank_nickname.max),
		beneficiary_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_name.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_name.min)
			.max(60, notificationsInfoFields.validationMessages.beneficiary_name.max),
		beneficiary_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_address.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_address.min)
			.max(140, notificationsInfoFields.validationMessages.beneficiary_address.max),
		beneficiary_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.beneficiary_country.required),
		bank_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_name.required)
			.min(1, notificationsInfoFields.validationMessages.bank_name.min)
			.max(60, notificationsInfoFields.validationMessages.bank_name.max),
		bank_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_address.required)
			.min(1, notificationsInfoFields.validationMessages.bank_address.min)
			.max(140, notificationsInfoFields.validationMessages.bank_address.max),
		bank_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.bank_country.required),
		bank_currency: yup.string().nullable().required('Please, select Currency.'),
		swift_bic: yup
			.string()
			.required(notificationsInfoFields.validationMessages.swift_bic.required)
			.min(8, notificationsInfoFields.validationMessages.swift_bic.min)
			.max(11, notificationsInfoFields.validationMessages.swift_bic.max),
		account_number: yup
			.string()
			.required(notificationsInfoFields.validationMessages.account_number.required)
			.min(5, notificationsInfoFields.validationMessages.account_number.min)
			.max(34, notificationsInfoFields.validationMessages.account_number.max),
		note: yup
			.string()
			.max(160, `Additional account information is too long. Maximum ${160} characters.`),
	});

	const onSubmit = (obj: IBankAccountRequestPayload) => {
		dispatch(createBankAccountRequest({ ...obj }));
		closeModal();
	};

	return (
		<Popup open={open} onClose={() => closeModal()}>
			<div className="modal">
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">Add Bank Account</p>
					</div>
					<div className="bankAccount bankAccount--popup">
						<Formik
							validationSchema={validationSchema}
							initialValues={initialInputValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								setSubmitting(false);
								resetForm();
								onSubmit({
									...obj,
									beneficiary_country: selectedBeneficiaryCountry?.value,
									bank_country: selectedBankCountry?.value,
									bank_currency: selectedCurrency?.id,
									// bank_currency: undefined,
								});
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ setFieldTouched, touched, isSubmitting, values, errors }) => (
								<Form className="form">
									<div className="form-body wallet-operations-item wallet-operations-item--popup">
										<Field
											title="Beneficiary Nickname"
											type="text"
											placeholder="Enter Beneficiary Nickname"
											name="bank_nickname"
											required
											component={Input}
										/>
										<Field
											title="Beneficiary Name"
											type="text"
											placeholder="Enter Beneficiary Name"
											name="beneficiary_name"
											required
											component={Input}
										/>
										<Field
											title="Beneficiary Address"
											type="textarea"
											placeholder="Enter Beneficiary Address"
											name="beneficiary_address"
											required
											component={Input}
										/>
										<Field
											setTouched={() => setFieldTouched('beneficiary_country')}
											touched={touched.beneficiary_country}
											title="Beneficiary Country"
											type="text"
											placeholder="Select Country"
											searchField
											name="beneficiary_country"
											required
											component={CountriesSelect}
											arr={countries}
											onChange={setSelectedBeneficiaryCountry}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											activeValue={selectedBeneficiaryCountry || undefined}
										/>
										{touched.beneficiary_country && !values.beneficiary_country && (
											<div className="input-notify input-notify--absolute">
												<span className="input-notify__char">*</span>
												<span className="input-notify__text">{errors.beneficiary_country}</span>
											</div>
										)}
										<Field
											title="Bank Name"
											type="text"
											placeholder="Enter Bank Name"
											name="bank_name"
											required
											component={Input}
										/>
										<Field
											title="SWIFT / BIC"
											type="text"
											placeholder="Enter SWIFT / BIC"
											name="swift_bic"
											required
											component={InputPattern}
										/>
										<Field
											title="Bank Address"
											type="textarea"
											placeholder="Enter Bank Address"
											name="bank_address"
											required
											component={Input}
										/>

										<Field
											setTouched={() => setFieldTouched('bank_country')}
											touched={touched.bank_country}
											title="Bank Country"
											type="text"
											placeholder="Select Country"
											searchField
											name="bank_country"
											required
											component={CountriesSelect}
											arr={countries}
											onChange={setSelectedBankCountry}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											activeValue={selectedBankCountry || undefined}
										/>
										{touched.bank_country && !values.bank_country && (
											<div className="input-notify input-notify--absolute">
												<span className="input-notify__char">*</span>
												<span className="input-notify__text">{errors.bank_country}</span>
											</div>
										)}

										<Field
											title="Currency"
											type="text"
											placeholder="Select Currency"
											dropdownTitle="Select Currency"
											name="bank_currency"
											searchField
											component={BankCurrencySelect}
											arr={currencies}
											touched={touched.bank_currency}
											onChange={setSelectedCurrency}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											activeValue={selectedCurrency || undefined}
										/>
										{touched.bank_currency && !values.bank_currency && (
											<div className="input-notify input-notify--absolute">
												<span className="input-notify__char">*</span>
												<span className="input-notify__text">{errors.bank_currency}</span>
											</div>
										)}

										<Field
											title="IBAN / Account number"
											type="text"
											placeholder="Enter IBAN / Account number"
											name="account_number"
											required
											component={Input}
										/>
										<Field
											title="Additional account information: Sort Code, BSB, ABA, Routing, etc."
											type="textarea"
											placeholder="Enter Additional Beneficiary Information. Example: Sort Code, BSB Number, Intermediary Bank or Routing Instructions."
											name="note"
											component={Input}
										/>
										<div className="popup-submit popup-submit--type2">
											<button
												type="submit"
												disabled={isSubmitting}
												className="btn btn-primary btn--full"
											>
												Confirm
											</button>
											<button
												type="button"
												onClick={closeModal}
												className="btn btn-danger btn--full"
											>
												Cancel
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AddBankAccount;
