export const toFixedNumber = (num: any, curr: any, isFormatted?: boolean, decimals?: number) => {
	const cryptoCurrency = ['BTC', 'LTC', 'BCH', 'ETH', 'TRX', 'XRP', 'crypto'];
	let isCrypto = false;
	let finalNumber = '';

	cryptoCurrency.forEach((e) => {
		if (e.toLowerCase() === curr.toLowerCase()) {
			isCrypto = true;
		}
	});

	const decimalPlaces = decimals ?? (isCrypto ? 5 : 2);
	const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
	const match = num.toString().match(re);
	finalNumber = match ? match[0] : '0';

	const [beforeDot, afterDot = ''] = finalNumber.split('.');
	const formattedBeforeDot = isFormatted ? Number(beforeDot).toLocaleString('en-US') : beforeDot;

	let adjustedAfterDot = afterDot;
	if (decimalPlaces > 0) {
		adjustedAfterDot = adjustedAfterDot.padEnd(decimalPlaces, '0');
	} else {
		adjustedAfterDot = '';
	}

	if (decimalPlaces > 0) {
		finalNumber = `${formattedBeforeDot}.${adjustedAfterDot}`;
	} else {
		finalNumber = formattedBeforeDot;
	}

	return finalNumber;
};
