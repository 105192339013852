import { ICurrencyDataItem } from '../../redux/reducers/currency/types';

export const determiningTheTypeOfTradingPair = (
	mainCurrency: ICurrencyDataItem[],
	from_asset_id: number,
	to_asset_id: number,
) => {
	const fromCurrency = mainCurrency.find((item) => item.id === from_asset_id);
	const toCurrency = mainCurrency.find((item) => item.id === to_asset_id);

	if (!fromCurrency || !toCurrency) {
		return 'trade';
	}

	const fiatTypes = ['fiat'];

	if (fiatTypes.includes(fromCurrency.type) && fiatTypes.includes(toCurrency.type)) {
		return 'same_asset_type_trade';
	}

	return 'trade';
};
