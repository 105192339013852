import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import Pagination from '../TransactionHistory/Pagination/Pagination';
import {
	getTradeHistory,
	getTradeHistoryPageCount,
} from '../../redux/reducers/transactions/selectors';
import PerPageSelect from '../../ui/PerPageSelect/PerPageSelect';
import UsersSearch from './UsersSearch';

const YourAccountsMain = () => {
	// const pageCount = useSelector(getTradeHistoryPageCount);
	const pageCount = 15;
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const from = useSelector(getTradeHistory)?.from || 1;
	const handleSetPerPage = (value: number | undefined) => {
		setCurrentPage(Math.ceil(from / Number(value)));
		setPerPage(value);
	};
	const history = useHistory();
	const accounts = [
		{
			dateAdded: 'Rejected',
			timeAdded: '',
			fullName: 'Cody Fisher',
			email: 'deanna.curtis@example.com',
			accountDetails: 'Cody Fisher EUR',
			roles: ['Viewer', 'Initiator', 'Approver'],
			status: 'rejected',
		},
		{
			dateAdded: 'Rejected',
			timeAdded: '',
			fullName: 'Brooklyn Simmons',
			email: 'nathan.roberts@example.com',
			accountDetails: 'Brooklyn Simmons EUR',
			roles: ['Initiator', 'Approver'],
			status: 'rejected',
		},
		{
			dateAdded: 'Rejected',
			timeAdded: '',
			fullName: 'Jacob Jones',
			email: 'jessica.hanson@example.com',
			accountDetails: 'Jacob Jones EUR',
			roles: ['Initiator', 'Approver'],
			status: 'rejected',
		},
		{
			dateAdded: 'Rejected',
			timeAdded: '16:47:53',
			fullName: 'Leslie Alexander',
			email: 'tanya.hill@example.com',
			accountDetails: 'Leslie Alexander EUR',
			roles: ['Trader'],
			status: 'rejected',
		},
	];
	const [query, setQuery] = useState<any>({
		current_page: 1,
		per_page: 10,
	});
	return (
		<div className="wallet-operations your-account-main">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button
						type="button"
						className="wallet-operations-header__back"
						onClick={() => history.goBack()}
					/>
					<p>Cody Fisher</p>
					<span>Main</span>
				</div>
			</div>
			<div className="table-block table-block--mt-0">
				<div className="title-block">
					<p className="title">Members</p>
				</div>
				<UsersSearch total={10} setQuery={setQuery} tab="user" includeOneifyId />
				<div className="table-wrapper">
					<div className="table table--your-account-main">
						<TableHeader />
						{accounts?.map((e) => {
							return (
								<>
									<TableBody item={e} />
								</>
							);
						})}
					</div>
					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>
						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={setCurrentPage}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourAccountsMain;
