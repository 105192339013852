export interface ISelected {
	changeSelectedItem: (item: any) => void;
	selectedItemsArray: [string, string];
	text: string;
}

export const selectedItemsArrayType = [
	{ id: 1, text: 'withdrawal' },
	{ id: 2, text: 'deposit' },
];
export const selectedItemsStatementArrayType = [
	{ id: 1, text: 'deposit' },
	{ id: 2, text: 'withdrawal' },
	{ id: 3, text: 'trade' },
];
export const selectedItemsArrayStatus = [
	{ id: 1, text: 'completed' },
	{ id: 2, text: 'failed' },
	{ id: 3, text: 'pending' },
	{ id: 4, text: 'cancelled' },
	{ id: 5, text: 'broadcasting' },
];
export const depositHistoryCryptoStatusFilters = [
	{ id: 1, text: 'admin_pending' },
	{ id: 2, text: 'broadcasting' },
	{ id: 3, text: 'completed' },
	// { id: 4, text: 'approval' },
	{ id: 5, text: 'rejected' },
	{ id: 6, text: 'failed' },
];
export const depositHistoryFiatStatusFilters = [
	{ id: 1, text: 'pending' },
	{ id: 2, text: 'completed' },
	// { id: 3, text: 'approval' },
	{ id: 4, text: 'rejected' },
	{ id: 5, text: 'cancelled' },
];
export const withdrawalHistoryCryptoStatusFilters = [
	{ id: 1, text: 'admin_pending' },
	{ id: 2, text: 'broadcasting' },
	{ id: 3, text: 'pending_signing' },
	{ id: 4, text: 'completed' },
	// { id: 5, text: 'approval' },
	{ id: 6, text: 'rejected' },
	{ id: 7, text: 'failed' },
];
export const withdrawalHistoryFiatStatusFilters = [
	{ id: 1, text: 'pending' },
	{ id: 2, text: 'completed' },
	// { id: 3, text: 'approval' },
	{ id: 4, text: 'rejected' },
	{ id: 5, text: 'cancelled' },
];
