import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import Pagination from '../TransactionHistory/Pagination/Pagination';
import {
	getTradeHistory,
	getTradeHistoryPageCount,
} from '../../redux/reducers/transactions/selectors';
import PerPageSelect from '../../ui/PerPageSelect/PerPageSelect';

const YourAccounts = () => {
	// const pageCount = useSelector(getTradeHistoryPageCount);
	const pageCount = 15;
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const from = useSelector(getTradeHistory)?.from || 1;
	const handleSetPerPage = (value: number | undefined) => {
		setCurrentPage(Math.ceil(from / Number(value)));
		setPerPage(value);
	};
	const accounts = [
		{
			accountName: 'Cody Fisher',
			main: 'Main',
			roles: ['Admin'],
			action: true,
		},
		{
			accountName: 'Praise Network LTD',
			main: '',
			roles: ['Viewer', 'Initiator', 'Approver'],
			action: false,
		},
		{
			accountName: 'Northbank Corp.',
			main: '',
			roles: ['Trader'],
			action: false,
		},
	];
	return (
		<div className="wallet-operations your-account">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					<p>Your accounts</p>
				</div>
			</div>
			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--your-account">
						<TableHeader />
						{accounts?.map((e) => {
							return (
								<>
									<TableBody item={e} />
								</>
							);
						})}
					</div>
					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>
						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={setCurrentPage}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourAccounts;
