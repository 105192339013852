import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom'; // Ensure correct import
import IconSvg from '../../../ui/Svg/IconSvg';
import { statusClassNames, statusNames } from '../../TransactionHistory/utils';

interface Account {
	dateAdded: string;
	timeAdded: string;
	fullName: string;
	email: string;
	accountDetails: string;
	roles: string[];
	status: string;
}

interface IProps {
	item: Account;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();

	const handleViewAccount = useCallback(() => {
		// This can be adjusted to use item-specific routes if necessary
		history.push(`/account-details/${item.fullName}`);
	}, [history, item.fullName]);

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<div className="td-hidden-name">Date Added</div>
					<div className="td__wrap">
						<div className="td__text">
							{item.dateAdded}
							{/* <span>{item.timeAdded}</span> */}
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Full Name</div>
					<div className="td__wrap">
						<div className="td__text">{item.fullName}</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Email</div>
					<div className="td__wrap">
						<div className="td__text">
							<a href="!#">{item.email}</a>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Account Details</div>
					<div className="td__wrap">
						<div className="td__text">{item.accountDetails}</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Roles</div>
					<div className="td__wrap">
						<div className="td__text">{item.roles.join(', ')}</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Status</div>
					<div className="td__wrap">
						<div className={`td__status ${statusClassNames[item.status]}`}>
							{statusNames[item.status]}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableBody;
