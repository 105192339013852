export type IRoundingNumber = (
	number?: number | string,
	value?: string,
	options?: {
		toFixedAll?: number;
		toFixedValue?: number;
		cryptoCurrency?: string[];
	},
) => string;

export const roundingNumber: IRoundingNumber = (number, value, options) => {
	const fiatDecimalPlaces = 2;
	const cryptoDecimalPlaces = 5;

	const currentValue = value || '';
	const toFixedAll = options?.toFixedAll ?? fiatDecimalPlaces;
	const toFixedValue = options?.toFixedValue ?? cryptoDecimalPlaces;
	const cryptoCurrency = options?.cryptoCurrency || [
		'BTC',
		'LTC',
		'BCH',
		'ETH',
		'TRX',
		'XRP',
		'crypto',
	];

	if (number === undefined || number === null) {
		return '-';
	}

	let requiredDecimalPlaces = toFixedAll;
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < cryptoCurrency.length; i++) {
		if (cryptoCurrency[i].toLowerCase() === currentValue.toLowerCase()) {
			requiredDecimalPlaces = toFixedValue;
			break;
		}
	}

	const numberString = typeof number === 'number' ? number.toString() : number;

	// eslint-disable-next-line no-restricted-globals
	if (isNaN(Number(numberString))) {
		return '-';
	}

	const [integerPart, decimalPart = ''] = numberString.split('.');

	let adjustedDecimalPart = decimalPart.substring(0, requiredDecimalPlaces);

	if (adjustedDecimalPart.length < requiredDecimalPlaces) {
		adjustedDecimalPart = adjustedDecimalPart.padEnd(requiredDecimalPlaces, '0');
	}

	let adjustedNumber = integerPart;
	if (requiredDecimalPlaces > 0) {
		adjustedNumber += `.${adjustedDecimalPart}`;
	}

	const [integerPartWithSpaces, decimalPartToFormat] = adjustedNumber.split('.');
	const integerWithSpaces = integerPartWithSpaces.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

	let finalNumber = integerWithSpaces;
	if (decimalPartToFormat !== undefined) {
		finalNumber += `.${decimalPartToFormat}`;
	}

	finalNumber = finalNumber.replace(/ /g, ',');

	return finalNumber;
};
