import { FC, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { IApiCryptoHistoryItem, IApiFiatHistoryItem } from 'services/api/transactions/types';
import InputFile from 'ui/Formik/InputFile';
import { useDispatch } from 'react-redux';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';
import Input from '../../../ui/Formik/Input';
import {
	createTradeDetailsRequest,
	downloadTradeFileRequest,
} from '../../../redux/reducers/detailedView/reducer';
import { IDetailedViewTradeDetails } from '../../../redux/reducers/detailedView/types';

interface ITradeHistoryDetailsForm {
	item: IDetailedViewTradeDetails | IApiCryptoHistoryItem | IApiFiatHistoryItem;
}

const TradeHistoryDetailsForm: FC<ITradeHistoryDetailsForm> = ({ item }) => {
	const dispatch = useDispatch();
	const initialValues = {
		client_notes: item.client_notes ? item.client_notes : '',
	};
	const [fileList, setFileList] = useState<any>([]);

	const validationSchema = yup.object().shape({
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const handleTradeFileDownload = (file: string) => {
		dispatch(
			downloadTradeFileRequest({
				file: file.split('/')[0],
			}),
		);
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createTradeDetailsRequest({
							id: item.id,
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length > 0 ? (
										item.files.map((file: any) => {
											return (
												<div
													key={file}
													className="input-file-preview__item"
													onClick={() => handleTradeFileDownload(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default TradeHistoryDetailsForm;
