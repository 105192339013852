import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import axios from 'axios';
import {
	downloadFilesRequest,
	getMessagesRequest,
	getMessagesSuccess,
	readMessageRequest,
	getUnreadMessageRequest,
	getUnreadMessageSuccess,
} from './reducer';

import {
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessageWithPagination,
	IReadMessageRequestPayload,
} from './types';

function* getMessagesWorker({ payload }: PayloadAction<IMessageRequestPayload>) {
	try {
		const data: IMessageWithPagination = yield call(api.messages.getMessages, payload);

		yield put(getMessagesSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadFilesWorker({ payload }: PayloadAction<IMessageDownloadFilesRequestPayload>) {
	try {
		yield call(api.messages.downloadFiles, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/cloud/msg/download/${payload.file}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

function* readMessageWorker({ payload }: PayloadAction<IReadMessageRequestPayload>) {
	try {
		yield call(api.messages.readMessage, payload);
		const data: IMessageWithPagination = yield call(api.messages.getMessages, {
			current_page: 1,
			per_page: 9999999,
		});
		yield put(getUnreadMessageSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUnreadMessagesWorker({ payload }: PayloadAction<IMessageRequestPayload>) {
	try {
		const data: IMessageWithPagination = yield call(api.messages.getMessages, payload);

		yield put(getUnreadMessageSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* messagesSaga() {
	yield takeLatest(getMessagesRequest, getMessagesWorker);
	yield takeLatest(downloadFilesRequest, downloadFilesWorker);
	yield takeLatest(readMessageRequest, readMessageWorker);
	yield takeLatest(getUnreadMessageRequest, getUnreadMessagesWorker);
}
