import React from 'react';
import { useHistory } from 'react-router';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';

interface IProps {
	id: number;
	name: string;
	color: string;
}

const Item: React.FC<IProps> = ({ id, name, color }) => {
	const history = useHistory();
	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<li
			onClick={() => {
				history.push('/wallets');
				setTimeout(() => {
					notificationContainer(notificationsMessagesInfo.loginSuccess, 'success', 'Welcome back!');
				}, 500);
			}}
			className="workspace-item"
		>
			<div className={`workspace-icon ${color}`}>
				<p>{name.slice(0, Math.min(2, name.length))}</p>
			</div>
			<span>{name}</span>
		</li>
	);
};

export default Item;
