export const ROUTES = {
	INDEX: '/',
	LOGIN: '/login',
	LOGIN_WORKSPACE: '/login/workspace',
	RESET_PASSWORD: '/reset-password',
	CREATE_PASSWORD: '/create-new-password',
	CREATE_TWOFA: '/create-new-2fa',
	RESET_TWOFA: '/reset-2fa',
	REGISTRATION: {
		CREATE_ACCOUNT: '/registration/create-account',
		DOCUMENT_VERIFICATION: '/registration/verification-documents',
		CONFIRMATION: '/confirmation',
		TWOFA: '/registration/twoFa',
	},
	TRADE: '/trade',
	DETAILED_TRADE_VIEW: '/trade/:id',
	WALLETS: {
		INDEX: '/wallets',
		DEPOSIT_CRYPTO: '/deposit-crypto',
		DEPOSIT_FIAT: '/deposit',
		WITHDRAW_CRYPTO: '/withdraw-crypto',
		WITHDRAW_FIAT: '/withdrawal',
	},
	YOUR_ACCOUNTS: {
		INDEX: '/your-accounts',
		MAIN: '/your-accounts/main',
	},
	SECURITY_SETTINGS: {
		INDEX: '/security-settings',
		TWOFA: '/security-settings/2fa',
	},
	BONUS_SYSTEM: {
		INDEX: '/referrals',
	},
	VERIFICATION: '/verification',
	PAYMENT_METHODS: '/beneficiaries',
	TRANSACTION_HISTORY: '/transaction-history',
	DEPOSIT_WITHDRAW: '/deposits-withdrawals',
	TRANSACTION_TRADE: '/history/trade',
	DEPOSITS_HISTORY: '/history',
	WITHDRAWALS_HISTORY: '/history',
	STATEMENT_HISTORY: '/history/statement',
	ACCOUNT_DETAILS: '/account-settings',
	TERMS_OF_USE: '/terms',
	PRIVACY_POLICY: '/privacy',
	INFORMATION: '/information',
};
