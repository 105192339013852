import { FC } from 'react';

const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Account Name</p>
				</div>
				<div className="td">
					<p>Roles</p>
				</div>
				<div className="td td-right">
					<p>Action</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
