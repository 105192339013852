import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrencyData, getFees } from 'redux/reducers/currency/selectors';
import { currencyDataRequest, feeDataRequest } from 'redux/reducers/currency/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getFeesByAssetRequest,
	getTradeExchangeRateRequest,
	getTradeQuoteRequest,
	getUserLimitsRequest,
	tradeInfoInitState,
} from 'redux/reducers/transactions/reducer';
import { getTradeData, getTradeLayers } from 'redux/reducers/trade/selectors';
import {
	changeAsset,
	changeAssetCount,
	swapTradeValues,
	tradeInitState,
	updateExchangeRateRaw,
	updateFromAssetCountRaw,
	updateLastInput,
	updateLayersLog,
	updateReversed,
	updateTradeFee,
	updateTradePairRaw,
} from 'redux/reducers/trade/reducer';
import {
	getLimitsByAsset,
	getQuoteError,
	getTradeInfo,
} from 'redux/reducers/transactions/selectors';
import { debounce } from 'ts-debounce';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { getPopUp } from 'redux/reducers/popUp/selectors';
import { toDinamicDecimals } from 'services/utils/numbers';
import TradeInput from './TradeInput/TradeInput';
import { ILayersData, layersFilter } from '../../../services/utils/layersFilter';
import { feeValueRequest, spreadFeeRequest } from '../../../redux/reducers/deposits/reducer';
import { getFeeValue, getSpreadFee } from '../../../redux/reducers/deposits/selectors';
import { getPriceLevel } from '../getPriceLevel';
import { calculatePercent, calculatePercentReverse } from '../calculatePercent';
import { calculateFee, calculateFeeReverse } from '../calculateFee';

import { layersFilterLog } from '../../../services/utils/layersFilterLog';
import { IFeeDataItem } from '../../../redux/reducers/currency/types';
import { determiningTheTypeOfTradingPair } from '../determiningTheTypeOfTradingPair';

type TTimeout = ReturnType<typeof setTimeout>;
let timeout: TTimeout;
const f = (fn: (value: string) => void, value: string) => fn(value);
const fnDebounce = debounce(f, 400) as (fn: (value: string) => void, value: string) => void;

export interface ITradeForm {
	layers?: Record<string, any>;
}

const TradeForm: FC<ITradeForm> = () => {
	const dispatch = useDispatch();
	const layers = useSelector(getTradeLayers);
	const mainCurrency = useSelector(getCurrencyData);
	const currency = mainCurrency.filter((el) => el.exchangeable);
	const wallets = useSelector(getWalletsList);
	const trade = useSelector(getTradeData);

	const tradeInfo = useSelector(getTradeInfo);
	const fromAssetLimits = useSelector(getLimitsByAsset);
	const currentPopup = useSelector(getPopUp);
	const notShowTrade = useSelector(getQuoteError);
	const spreadFee = useSelector(getSpreadFee);
	const feeValue = useSelector(getFeeValue);
	const allFees = useSelector(getFees);

	const [sellInputError, setSellInputError] = useState(false);
	const [buyInputError, setBuyInputError] = useState(false);
	const [sellCurrency, setSellCurrency] = useState(currency);
	const [buyCurrency, setBuyCurrency] = useState(currency);
	const [sellType, setSellType] = useState('crypto');
	const [buyType, setBuyType] = useState('fiat');
	const [swap, setSwap] = useState(true);
	const [tradeBuyLayers, setTradeBuyLayers] = useState<Record<string, any> | null>(null);
	const [tradeSellLayers, setTradeSellLayers] = useState<Record<string, any> | null>(null);
	const [reversed, setReversed] = useState(false);
	const [reversedServer, setReversedServer] = useState(false);
	const [tradePairState, setTradePairState] = useState('');
	const [tradeType, setTradeType] = useState('sell');
	const [assetFees, setAssetFees] = useState<IFeeDataItem | null>(null);

	const [buttonDisabled, setButtonDisabled] = useState(false);

	const autoGetTradeExchangeRate = (assetFrom: string | null, assetTo: string | null) => {
		// clearTimeout(timeout);
		if (currentPopup.popUpText === 'successTrade') return;
		if (
			trade.from_asset_id !== null &&
			trade.to_asset_id !== null &&
			trade.from_asset_code !== null &&
			trade.to_asset_code !== null
		) {
			const params = {
				from_asset_id: assetFrom || '',
				to_asset_id: assetTo || '',
				pair_code: `${trade.from_asset_code}_${trade.to_asset_code}`,
			};
			dispatch(getTradeExchangeRateRequest(params));
		}
		// timeout = setTimeout(() => autoGetTradeExchangeRate(assetFrom, assetTo), 10000);
	};

	const calculateSellInputValue = useCallback(
		(value: string) => {
			const count = Number(value);
			let perc: string | number = 0;
			let calculatedValue;
			let calculatedValueRaw;
			let tradeFee;
			if (spreadFee) {
				perc = Number(spreadFee);
			}
			if (count && tradeSellLayers && tradeBuyLayers && feeValue && assetFees) {
				let price = 0;
				// let selectedLevel = null;
				let selectedLevelFinal = null;

				selectedLevelFinal = getPriceLevel(
					tradeSellLayers,
					tradeBuyLayers,
					count,
					reversed,
					'sell',
				);

				if (reversed) {
					price = Number(tradeBuyLayers[selectedLevelFinal]);
				} else {
					price = Number(tradeSellLayers[selectedLevelFinal]);
				}

				if (reversed) {
					const spreadCalc = calculatePercent(Number(perc), price);
					tradeFee = calculateFeeReverse(
						Number(feeValue.fee_percent),
						Number(feeValue.fixed_fee),
						Number(count) * (price + spreadCalc),
					);
					calculatedValue = Number(count) * (price + Number(spreadCalc)) + tradeFee;
					calculatedValueRaw = Number(count) * price;
				} else {
					let spreadCalc = calculatePercent(Number(perc), price);
					if (reversedServer) {
						tradeFee = calculateFeeReverse(
							Number(feeValue.fee_percent),
							Number(feeValue.fixed_fee),
							Number(count) * price,
						);
					} else {
						tradeFee = calculateFeeReverse(
							Number(feeValue.fee_percent),
							Number(feeValue.fixed_fee),
							Number(count) / (price - spreadCalc),
						);
					}
					calculatedValue = Number(count) / (price - Number(spreadCalc)) + tradeFee;
					calculatedValueRaw = Number(count) / price;
					if (reversedServer) {
						spreadCalc = calculatePercent(Number(perc), price);
						calculatedValue = Number(count) * (price + Number(spreadCalc)) + tradeFee;
						calculatedValueRaw = Number(count) * price;
					}
				}
				dispatch(updateTradeFee(tradeFee));
				dispatch(updateReversed(reversed));
				dispatch(updateExchangeRateRaw(price));
				dispatch(updateFromAssetCountRaw(calculatedValueRaw));
				dispatch(
					changeAssetCount({
						type: 'from',
						asset_count: String(toDinamicDecimals(calculatedValue, sellType)),
						// asset_count: String(calculatedValue),
					}),
				);
				dispatch(updateLastInput('You Receive'));
			}
		},
		[
			spreadFee,
			tradeSellLayers,
			tradeBuyLayers,
			feeValue,
			assetFees,
			reversed,
			dispatch,
			sellType,
			reversedServer,
		],
	);

	const calculateBuyInputValue = useCallback(
		(value: string) => {
			const count = Number(value);
			if (count && tradeSellLayers && tradeBuyLayers && feeValue && assetFees) {
				let selectedLevelFinal = null;
				let price = 0;
				let perc: string | number = 0;
				if (spreadFee) {
					perc = Number(spreadFee);
				}

				selectedLevelFinal = getPriceLevel(tradeSellLayers, tradeBuyLayers, count, reversed);
				if (reversed) {
					price = Number(tradeBuyLayers[selectedLevelFinal]);
				} else {
					price = Number(tradeSellLayers[selectedLevelFinal]);
				}
				const tradeFee = calculateFee(
					Number(feeValue.fee_percent),
					Number(feeValue.fixed_fee),
					Number(count),
				);

				let calculatedValue;

				const spreadCalc = calculatePercent(Number(perc), Number(price));
				calculatedValue = (Number(count) - tradeFee) * (price - Number(spreadCalc));
				if (reversed || reversedServer) {
					calculatedValue = (Number(count) - tradeFee) / (price + Number(spreadCalc));
				}

				dispatch(updateTradeFee(tradeFee));
				if (reversedServer) {
					dispatch(updateReversed(true));
				} else {
					dispatch(updateReversed(reversed));
				}
				dispatch(updateExchangeRateRaw(price));
				dispatch(updateFromAssetCountRaw(Number(count)));
				dispatch(
					changeAssetCount({
						type: 'to',
						asset_count: String(toDinamicDecimals(calculatedValue, buyType)),
						// asset_count: String(calculatedValue),
					}),
				);
				dispatch(updateLastInput('You Sell'));
			}
		},
		[
			assetFees,
			buyType,
			dispatch,
			feeValue,
			reversed,
			reversedServer,
			spreadFee,
			tradeBuyLayers,
			tradeSellLayers,
		],
	);

	/* GET LAYERS */
	useEffect(() => {
		if (currentPopup.popUpText === 'successTrade') return;

		if (layers && trade.from_asset_code && trade.to_asset_code) {
			const fromCode = trade.from_asset_code;
			const toCode = trade.to_asset_code;
			const tradePairReverse = `${toCode}_${fromCode}`;
			const layersData: ILayersData | Record<string, any> = layersFilter(
				`${fromCode}_${toCode}`,
				layers,
			);
			const layersDataLog: ILayersData | Record<string, any> = layersFilterLog(
				`${fromCode}_${toCode}`,
				layers,
			);
			dispatch(updateLayersLog(layersDataLog));
			const layersDataReverse: ILayersData | Record<string, any> = layersFilter(
				tradePairReverse,
				layers,
			);
			setTradePairState(`${trade.from_asset_code}_${trade.to_asset_code}`);

			if (layersData && layersData.data) {
				const { isReversed, isReversedServer } = layersData;
				setReversed(isReversed);
				setReversedServer(isReversedServer);
				setButtonDisabled(false);
				// if ((sellType === 'fiat' || sellType === 'token') && buyType === 'crypto') {
				// 	dispatch(updateTradePairRaw(`${trade.to_asset_code}_${trade.from_asset_code}`));
				// }
				if (isReversed) {
					dispatch(updateTradePairRaw(`${trade.to_asset_code}_${trade.from_asset_code}`));
				} else {
					dispatch(updateTradePairRaw(`${trade.from_asset_code}_${trade.to_asset_code}`));
				}
				setTradeBuyLayers(layersData.data.buy);
				setTradeSellLayers(layersData.data.sell);
				if (trade.from_asset_count !== null && tradeType === 'sell') {
					calculateBuyInputValue(String(trade.from_asset_count));
				}
				if (trade.to_asset_count !== null && tradeType === 'receive') {
					calculateSellInputValue(String(trade.to_asset_count));
				}
			} else if (layersData && !layersData.data) {
				setTradeBuyLayers(null);
				setTradeSellLayers(null);
				setButtonDisabled(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculateBuyInputValue,
		calculateSellInputValue,
		currentPopup.popUpText,
		layers,
		trade.from_asset_code,
		trade.from_asset_count,
		trade.to_asset_code,
		trade.to_asset_count,
		tradeType,
	]);
	/* END GET LAYERS */

	// useEffect(() => {
	// 	console.log(tradeBuyLayers);
	// 	console.log(tradeSellLayers);
	// }, [tradeBuyLayers, tradeSellLayers]);

	useEffect(() => {
		if (trade.from_asset_id && trade.to_asset_id) {
			const payload = {
				from: Number(trade.from_asset_id),
				to: Number(trade.to_asset_id),
			};
			dispatch(spreadFeeRequest(payload));
			dispatch(
				feeValueRequest({
					asset_id: trade.from_asset_id,
					type: determiningTheTypeOfTradingPair(
						mainCurrency,
						Number(trade.from_asset_id),
						Number(trade.to_asset_id),
					),
					amount: 1,
				}),
			);
			dispatch(feeDataRequest());
		}
	}, [dispatch, trade.from_asset_id, trade.to_asset_id]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (allFees && trade.from_asset_id) {
			const filteredArray = Object.values(allFees).filter(
				(item) => item.asset_id === Number(trade.from_asset_id),
			);
			setAssetFees(filteredArray[0]);
		}
	}, [allFees, trade.from_asset_id]);

	useEffect(() => {
		autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trade]);

	useEffect(() => {
		setSellCurrency(currency.filter((el) => el.type !== 'fiat'));
		setBuyCurrency(currency.filter((el) => el.code !== trade.from_asset_code));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency]);

	const getTradeExchangeRate = (assetId: string, assetType: string) => {
		const fromAsset = assetType === 'from' ? assetId : trade.from_asset_id;
		const toAsset = assetType === 'to' ? assetId : trade.to_asset_id;
		if (fromAsset !== toAsset) {
			autoGetTradeExchangeRate(fromAsset, toAsset);

			if (Number(fromAsset) !== 0) {
				dispatch(getUserLimitsRequest(Number(fromAsset)));
			}
		}
	};

	const swapSellAndBuy = () => {
		setSwap(!swap);
		autoGetTradeExchangeRate(trade?.to_asset_id, trade?.from_asset_id);
		if (Number(trade?.to_asset_id) !== 0) {
			dispatch(getUserLimitsRequest(Number(trade?.to_asset_id)));
		}
		dispatch(swapTradeValues());
		setSellCurrency(buyCurrency);
		setBuyCurrency(sellCurrency);
		setSellType(buyType);
		setBuyType(sellType);
	};

	/* useEffect(() => {
		if (trade?.from_asset_count != null) {
			calculateBuyInputValue(trade?.from_asset_count);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeInfo?.price]); */

	const sellInputChange = (value: string, error: boolean) => {
		setTradeType('sell');
		dispatch(
			changeAssetCount({
				type: 'from',
				asset_count: String(toDinamicDecimals(value, sellType)),
				// asset_count: String(value),
			}),
		);
		setSellInputError(error);
		!error && fnDebounce(calculateBuyInputValue, value);
	};
	const buyInputChange = (value: string, error: boolean) => {
		setTradeType('receive');
		dispatch(
			changeAssetCount({
				type: 'to',
				asset_count: String(toDinamicDecimals(value, buyType)),
				// asset_count: String(value),
			}),
		);
		setBuyInputError(error);
		!error && fnDebounce(calculateSellInputValue, value);
	};

	const sellSelectChange = (value: string, id: number) => {
		dispatch(
			changeAsset({
				type: 'from',
				asset_id: id.toString(),
				asset_code: value,
			}),
		);
		getTradeExchangeRate(id.toString(), 'from');
	};
	const buySelectChange = (value: string, id: number) => {
		dispatch(
			changeAsset({
				type: 'to',
				asset_id: id.toString(),
				asset_code: value,
			}),
		);
		getTradeExchangeRate(id.toString(), 'to');
	};
	const fiatArrCodeFunc = (arr: any, type: string) => {
		return arr.filter((obj: any) => obj.type === type);
	};

	const openConfirmTradePopup = () => {
		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являются криптой
		function splitText(text: string) {
			const [oneText, twoText] = text.split('_');
			return [oneText, twoText];
		}
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};
		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используються значит true
		const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		if (trade.from_asset_id !== null && trade.to_asset_id !== null) {
			dispatch(
				getTradeQuoteRequest({
					from_asset_id: trade.from_asset_id,
					to_asset_id: trade.to_asset_id,
					quantity: Number(quantityToFrom()),
				}),
			);
		}
	};
	useEffect(() => {
		if (!notShowTrade) {
			dispatch(popUpOpen('confirmTrade'));
			dispatch(
				setPopUpData({
					data: sellType,
				}),
			);
			dispatch(getFeesByAssetRequest(Number(trade.from_asset_id)));
		}
	}, [dispatch, notShowTrade, trade.from_asset_id, sellType]);

	useEffect(() => {
		if (Number(trade?.from_asset_id) !== 0) {
			dispatch(getUserLimitsRequest(Number(trade.from_asset_id)));
		}
	}, [dispatch, trade.from_asset_id]);
	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(currencyDataRequest());
		if (Number(trade?.from_asset_id) !== 0) {
			dispatch(getUserLimitsRequest(Number(trade.from_asset_id)));
		}
		if (localStorage.asset) {
			const asset = JSON.parse(localStorage.asset);
			if (asset?.asset_id === Number(trade?.to_asset_id)) {
				swapSellAndBuy();
			} else {
				sellSelectChange(asset?.asset_code, Number(asset?.asset_id));
				setSellType(asset.type);
			}
		} else {
			autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		}

		return () => {
			dispatch(tradeInitState());
			dispatch(tradeInfoInitState());
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPopup]);

	useEffect(() => {
		const changeCurrency = currency.find((el) => el.code === trade.from_asset_code);
		if (changeCurrency?.type === 'fiat') {
			setBuyCurrency(
				currency.filter((el) => el.type !== 'fiat' && el.code !== trade.from_asset_code),
			);
		} else {
			setBuyCurrency(currency.filter((el) => el.code !== trade.from_asset_code));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency, trade.from_asset_code]);

	useEffect(() => {
		const changeCurrency = currency.find((el) => el.code === trade.to_asset_code);
		if (changeCurrency?.type === 'fiat') {
			setSellCurrency(
				currency.filter((el) => el.type !== 'fiat' && el.code !== trade.to_asset_code),
			);
		} else {
			setSellCurrency(currency.filter((el) => el.code !== trade.to_asset_code));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency, trade.to_asset_code]);
	const [accountBalanceError, setAccountBalanceError] = useState(false);
	return (
		<div>
			<div className="instant-trade-form">
				<div className="instant-trade-form-item">
					{trade.from_asset_id !== null && trade.to_asset_id !== null ? (
						<TradeInput
							swap={swap}
							wallets={wallets}
							currency={currency}
							allCurrency={currency}
							inputError={sellInputError}
							inputValue={trade.from_asset_count ? trade.from_asset_count : ''}
							selectValue={trade.from_asset_code ? trade.from_asset_code : ''}
							selectValueOtherElement={trade.to_asset_code ? trade.to_asset_code : ''}
							onInputChange={sellInputChange}
							onSelectChange={sellSelectChange}
							setInputError={setSellInputError}
							limits={fromAssetLimits}
							setType={setSellType}
							setAccountBalanceError={setAccountBalanceError}
							title="You Sell"
						/>
					) : null}
				</div>
				<div className="instant-trade-switch-button">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" onClick={swapSellAndBuy} />
				</div>
				<div className="instant-trade-form-item">
					{trade.from_asset_id !== null && trade.to_asset_id !== null ? (
						<TradeInput
							swap={swap}
							wallets={wallets}
							currency={currency}
							allCurrency={currency}
							inputError={buyInputError}
							inputValue={trade.to_asset_count ? trade.to_asset_count : ''}
							selectValue={trade.to_asset_code ? trade.to_asset_code : ''}
							selectValueOtherElement={trade.from_asset_code ? trade.from_asset_code : ''}
							onInputChange={buyInputChange}
							onSelectChange={buySelectChange}
							setInputError={setBuyInputError}
							setType={setBuyType}
							setAccountBalanceError={setAccountBalanceError}
							title="You Receive"
						/>
					) : null}
				</div>
			</div>
			<button
				type="button"
				className="btn btn-primary btn--full"
				onClick={openConfirmTradePopup}
				disabled={
					!(
						!sellInputError &&
						!buyInputError &&
						Number(trade.from_asset_count) > 0 &&
						!!trade.to_asset_count &&
						trade.from_asset_id !== trade.to_asset_id &&
						!!tradeInfo?.price &&
						!buttonDisabled &&
						!accountBalanceError
					)
				}
			>
				Trade
			</button>
		</div>
	);
};

export default TradeForm;
