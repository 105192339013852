import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IPopUp } from '../types';

const SuccessDepositPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();

	const closeHandler = () => {
		closeModal();
		history.push('/wallets');
	};

	return (
		<>
			<Popup open={open} onClose={closeModal} closeOnDocumentClick={false}>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header popup-header--mb-20">
							<p className="popup-header__title">Your deposit request has been received.</p>
						</div>
						<div className="popup-icon popup-icon--type2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="58"
								height="58"
								viewBox="0 0 58 58"
								fill="none"
							>
								<path
									d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
									fill="#007F00"
									stroke="#007F00"
									strokeOpacity="0.06"
									strokeWidth="16"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
								<path
									d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
									stroke="white"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="popup-text">
							<p>
								Thank you for submitting your deposit request. Once you complete the transfer to the
								the account provided, we will update your balance too accordingly.
								<br />
								<br />
								If you have any issues or would like to cancel your request, please reach out to our
								team at <a href="mailto:support@oneify.money">support@oneify.money</a>
							</p>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeHandler} type="button" className="btn btn-primary btn--full">
									Home
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SuccessDepositPopUp;
