import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';

import Auth from 'layouts/Auth';
import {
	currencyDataRequest,
	feeDataRequest,
	limitsDataRequest,
} from 'redux/reducers/currency/reducer';
import {
	getFeesByAssetRequest,
	postFiatDepositInvoiceCreateRequest,
} from 'redux/reducers/transactions/reducer';
import {
	getCryptoCurrencyData,
	getFees,
	getFiatCurrencyData,
	getLimits,
} from 'redux/reducers/currency/selectors';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import Input from 'ui/Formik/Input';
import IconSvg from 'ui/Svg/IconSvg';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import InvoiceGenerate from 'layouts-elements/PopUp/InvoiceGenerate/InvoiceGenerate';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IFiatDepositGetFile } from 'services/api/transactions/types';
import CopyToClipboard from 'react-copy-to-clipboard';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import InputFile from 'ui/Formik/InputFile';
import { ILimitItem } from 'redux/reducers/currency/types';
import { getDepositAddress, getFee } from '../../../redux/reducers/deposits/selectors';
import {
	depositAddressRequest,
	depositsInitialState,
	feeRequest,
} from '../../../redux/reducers/deposits/reducer';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { navList } from '../../../routes/routesList';
import { getTradeInfo } from '../../../redux/reducers/transactions/selectors';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import SuccessDepositPopUp from '../../../layouts-elements/PopUp/SuccessDepositPopUp/SuccessDepositPopUp';

interface IPayloadData {
	asset_id: number;
	amount: number;
	fee: number;
	reference?: string | undefined;
	client_notes?: string | undefined;
	fileList?: File[];
	status: string;
}
interface FormValues {
	currency: string;
	amount: string;
	depositSummary: string;
	reference: string;
	instructions: string;
}
const DepositFiatPage = () => {
	const dispatch = useDispatch();
	const fees = useSelector(getFees);
	const fiatCurrency = useSelector(getFiatCurrencyData);
	const cryptoCurrency = useSelector(getCryptoCurrencyData);
	const tradeInfo = useSelector(getTradeInfo);
	const depositAddress = useSelector(getDepositAddress);
	const limits = useSelector(getLimits);
	const [selectedFiat, setSelectedFiat] = useState<ICurrencySelectItem | null>(null);
	const [selectedCurrency, setSelectedCurrency] = useState<ICurrencySelectItem | null>(null);
	const [fiatCode, setFiatCode] = useState('');
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openSuccessDepositPopUp, setOpenSuccessDepositPopUp] = useState(false);
	const [fiatCoinPercent, setFiatCoinPercent] = useState<number>(0);
	const [currencyList, setCurrencyList] = useState<any>();
	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<ICurrencySelectItem | null>(null);
	const [fileList, setFileList] = useState<any>([]);
	const [payloadData, setPayloadData] = useState<IPayloadData>({
		asset_id: 0,
		amount: 0,
		fee: 0,
		status: 'pending',
	});
	const [noRounding, setNoRounding] = useState('');
	const [currencyLimits, setCurrencyLimits] = useState<ILimitItem | null>(null);
	const closeModal = () => {
		setOpenModal(false);
		// history.push(ROUTES.WALLETS);
	};
	const closeSuccessDepositPopUp = () => {
		setOpenSuccessDepositPopUp(false);
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	const [btcNetworksArr, setBtcNetworksArr] = useState([
		{
			id: 1,
			code: 'Legacy',
			name: 'Bitcoin Legacy',
		},
		{
			id: 2,
			code: 'Segwit',
			name: 'Bitcoin Segwit',
		},
	]);

	const depositMin = Number(selectedFiat?.deposit_min);
	// const depositMax = Number(selectedFiat?.deposit_max);
	const depositMax = Number(10000000);

	const initialValues = {
		currency: '',
		amount: '',
		depositSummary: '',
		reference: '',
		instructions: '',
	};
	const [previousValue, setPreviousValue] = useState(0);

	const validationSchema = yup.object().shape({
		currency: yup.string().required(notificationsInfoFields.currency.required),
		amount: yup
			.number()
			.required(notificationsInfoFields.amount.required)
			.min(
				Number(currencyLimits?.minimum),
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${notificationsInfoFields.amount.min} ${currencyLimits?.minimum}`,
			)
			.max(
				Number(currencyLimits?.maximum),
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${notificationsInfoFields.amount.max} ${currencyLimits?.maximum}`,
			)
			.test('is-valid', 'Invalid amount', function (value) {
				if (value !== previousValue) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					if (Number(value) !== 0 && selectedFiat?.id !== undefined && value && value > 0) {
						const payload = {
							asset_id: selectedFiat?.id,
							type: 'deposit',
							amount: value,
						};
						// Отправляем запрос на сервер
						dispatch(feeRequest(payload));
						setPreviousValue(value);
					}
				}

				const { path, createError } = this;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// if (value < Number(currencyLimits?.minimum)) {
				// 	return createError({
				// 		path,
				// 		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// 		// @ts-ignore
				// 		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				// 		message: `${notificationsInfoFields.amount.min} ${currencyLimits?.minimum}`,
				// 	});
				// }
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (value > Number(currencyLimits?.maximum)) {
					return createError({
						path,
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						message: `${notificationsInfoFields.amount.max} ${currencyLimits?.maximum}`,
					});
				}
				return true;
			}),
		depositSummary: yup
			.number()
			.typeError('Please enter Amount.')
			.required(notificationsInfoFields.depositSummary.required)
			.test('is-valid', 'Invalid amount', function (value) {
				const { path, createError } = this;

				// Проверка на undefined или null
				if (value === undefined || value === null) {
					return createError({
						path,
						message: 'Amount is required',
					});
				}

				// Проверка максимального значения
				if (value > Number(currencyLimits?.maximum ?? 0)) {
					return createError({
						path,
						message: `${notificationsInfoFields.amount.max} ${currencyLimits?.maximum ?? 0}`,
					});
				}

				return true;
			}),
		reference: yup.string().max(255, notificationsInfoFields.reference.max),
		instructions: yup.string().max(255, notificationsInfoFields.instructions.max),
	});

	const getCalcAmount = (value: string) => {
		const numValue = Number(value);
		let percent = 0;
		let receivedFee = 0;

		if (selectedFiat && fees) {
			percent = fees[selectedFiat.id].deposit_fee_percent;
			const percentFix = fees[selectedFiat.id].deposit_fee_fixed;
			receivedFee = (percent * numValue) / 100;
			setFiatCoinPercent(receivedFee);
			if (receivedFee < percentFix) {
				receivedFee = percentFix;
			}
		}
		const fee = receivedFee > 0 ? receivedFee : '0';
		const receive = numValue - receivedFee > 0 ? numValue - receivedFee : '0';
		let feeNew = 0;
		if (selectedFiat && fees) {
			feeNew = Number(fee) + Number(fees[selectedFiat.id].deposit_fee_fixed);
		}
		return { fee, receive, feeNew };
	};

	const getCalcAmountDes = (value: string | number, isGross = true) => {
		const numValue = Number(value);
		let percent = 0; // Процентная комиссия
		let percentFix = 0; // Фиксированная комиссия
		// Получаем значения комиссий
		if (selectedFiat && fees) {
			percent = fees[selectedFiat.id]?.deposit_fee_percent || 0;
			percentFix = fees[selectedFiat.id]?.deposit_fee_fixed || 0;
		}
		// Функция для форматирования числа с двумя знаками после запятой
		const formatAmount = (amount: number) => {
			return amount.toFixed(2); // Всегда выводим два знака после точки
		};

		if (isGross) {
			// Грязная сумма (Amount): расчет суммы для депозита без учета комиссии
			const amountToPay = (numValue + percentFix) / (1 - percent / 100);
			// Вызов функции setNoRounding с грязной суммой
			setNoRounding(String(amountToPay));
			return formatAmount(amountToPay); // Округляем и форматируем значение как строку
		}

		// Чистая сумма (Deposit Summary): расчет суммы, которую пользователь получит на руки после всех комиссий
		const netAmount = numValue - percentFix - (numValue * percent) / 100;
		setNoRounding(String(numValue));
		return formatAmount(netAmount);
	};
	const transactionSubmit = (obj: IFiatDepositGetFile) => {
		const formData = new FormData();
		fileList &&
			fileList.length &&
			fileList.forEach((file: string | Blob) => formData.append(`files[]`, file));

		Object.keys(obj).forEach((key) => {
			const value = obj[key as keyof typeof obj];
			if (value !== undefined) {
				formData.append(key, value.toString());
			}
		});
		dispatch(postFiatDepositInvoiceCreateRequest({ body: formData }));
	};

	const handleGetBtcAddress = (code: string) => {
		if (code === 'Legacy') {
			return selectedCoin?.addresses?.legacy || '';
		}
		if (code === 'Segwit') {
			return selectedCoin?.addresses?.segwit_address || '';
		}
		return '';
	};

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(feeDataRequest());
		if (localStorage.asset && fiatCurrency) {
			const currentFiat =
				fiatCurrency.find(
					(el: ICurrencySelectItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentFiat) {
				setSelectedFiat(currentFiat);
			}
		}
		if (localStorage.asset && cryptoCurrency) {
			const currentCrypto =
				cryptoCurrency.find(
					(el: ICurrencySelectItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentCrypto) {
				setSelectedFiat(currentCrypto);
			}
		}
		return () => {
			localStorage.removeItem('asset');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!localStorage.asset) {
			let firstDepositableCurrency = fiatCurrency.find(
				(currency) => currency.depositable === 1 && currency.code === 'eur',
			);
			if (!firstDepositableCurrency) {
				firstDepositableCurrency = fiatCurrency.find((currency) => currency.depositable === 1);
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setSelectedFiat(firstDepositableCurrency);
		}
		setCurrencyList([
			...fiatCurrency.filter((el) => el.depositable),
			...cryptoCurrency.filter((el) => el.depositable),
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiatCurrency, cryptoCurrency]);

	// useEffect(() => {
	// 	console.log(currencyList);
	// }, [currencyList]);

	useEffect(() => {
		if (selectedFiat && selectedFiat?.type === 'fiat') {
			setFiatCode(selectedFiat?.code.toUpperCase());
			setSelectedCurrency(selectedFiat);
			setSelectedCoin(null);
		}
		if (selectedFiat && selectedFiat?.type !== 'fiat') {
			setSelectedCurrency(selectedFiat);
			setSelectedCoin(selectedFiat);
		}
	}, [selectedFiat]);
	useEffect(() => {
		if (selectedNetwork?.pivot) {
			dispatch(depositAddressRequest(selectedNetwork.pivot));
		}
	}, [dispatch, selectedNetwork]);

	useEffect(() => {
		if (selectedCoin) {
			const btcNetworksArrFiltered = btcNetworksArr.filter(({ code }) => code === 'Segwit');
			dispatch(getFeesByAssetRequest(selectedCoin.id));
			setSelectedNetwork(
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				selectedCoin.code === 'btc' ? btcNetworksArrFiltered[0] : selectedCoin?.chains?.[0] || null,
			);
		}
	}, [selectedCoin, dispatch, btcNetworksArr]);
	useEffect(() => {
		dispatch(limitsDataRequest());
	}, [dispatch]);

	useEffect(() => {
		if (limits) {
			setCurrencyLimits(
				limits.filter((item) => item.asset_id === (selectedCoin?.id || selectedCurrency?.id))[0],
			);
		}
	}, [selectedCurrency, selectedCoin, limits]);
	const fee = useSelector(getFee);
	const formikRef = useRef<FormikProps<FormValues>>(null);

	const cleanFunc = () => {
		if (formikRef?.current) {
			const currentValues = formikRef.current.values;
			const newValues = {
				...formikRef.current.initialValues,
				currency: currentValues.currency,
			};
			formikRef.current.resetForm({ values: newValues });
			setFileList([]);
		}
	};
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-operations"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<div className="wallet-content-header">
							<div className="wallet-content-header__title">
								<p>Deposit Instructions</p>
							</div>
							<div className="wallet-content-header__buttons flex flex-e flex-m">
								<Link to={navList.trade.path} className="btn btn-primary">
									Trade
								</Link>
								<Link
									to={navList.depositFiat.path}
									className="btn btn-primary btn-primary--blue300"
								>
									Deposit
								</Link>
								<Link
									to={navList.withdrawFiat.path}
									className="btn btn-primary btn-primary--blue100"
								>
									Withdraw
								</Link>
							</div>
						</div>
						<div className="wallet-operations__form-wrap">
							<Formik
								innerRef={formikRef}
								validationSchema={validationSchema}
								initialValues={initialValues}
								onSubmit={(values, { setSubmitting, setValues }) => {
									const amount = getCalcAmount(values.amount);
									const depositSummary = Number(amount) - Number(fee);
									/* transactionSubmit({
										asset_id: Number(selectedFiat?.id),
										amount: Number(values.amount),
										fee: Number(amount.fee),
										reference: values.reference,
										instructions: values.instructions,
									}); */
									setPayloadData({
										asset_id: Number(selectedFiat?.id),
										amount: Number(noRounding),
										fee: Number(amount.fee),
										reference: values.reference,
										client_notes: values.instructions,
										fileList,
										status: 'pending',
									});
									setOpenModal(true);
									// resetForm();
									setSubmitting(false);
									// setResetCustomSelect(true);
									// setSelectedFiat(null);
								}}
							>
								{({
									isSubmitting,
									setFieldTouched,
									setFieldValue,
									values,
									handleChange,
									validateField,
									handleBlur,
								}) => {
									const amount = getCalcAmount(values.amount);

									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									// @ts-ignore
									const isAmountValid =
										values.amount === '' || Number(values.amount) - Number(fee) > 0;
									return (
										<Form className="form">
											<div className="wallet-operations-item coin__text-normal">
												<div className="wallet-operations-item__title">
													<span>1</span>
													<p>Select Currency</p>
												</div>
												<div className="authoriztion-item">
													<Field
														type="text"
														placeholder="Select Currency"
														dropdownTitle="Select Currency"
														name="currency"
														required
														component={CurrencySelect}
														arr={currencyList}
														onChange={(value: React.SetStateAction<ICurrencySelectItem | null>) => {
															setFieldValue('amount', '');
															setFieldValue('depositSummary', '');
															setNoRounding('');
															setSelectedFiat(value);
															setFieldTouched('amount', false);
															dispatch(depositsInitialState());
														}}
														resetCustomSelect={resetCustomSelect}
														setResetCustomSelect={setResetCustomSelect}
														activeValue={selectedFiat || undefined}
														tabs
													/>
												</div>
											</div>

											<>
												{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
												{/* @ts-ignore */}
												{selectedCurrency?.type === 'fiat' ? (
													<>
														<div
															className="wallet-operations-item wallet-operations-item--border-top"
															style={{ display: 'none' }}
														>
															<div className="wallet-operations-network">
																<div className="wallet-operations-item__title">
																	<span>2</span>
																	<p>Deposit Method</p>
																</div>
																<div className="wallet-operations-network__item">
																	<div className="radio">
																		<label className="radio__label">
																			<Field
																				className="hidden"
																				type="radio"
																				checked
																				name="pay_with"
																			/>
																			<span className="radio__item" />
																			<span className="radio__text">
																				<span className="radio__text-icon">
																					<IconSvg name="bank" w="18" h="18" />
																				</span>
																				Bank Transfer
																			</span>
																		</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top amount-input">
															<div className="wallet-operations-item__title">
																<span>2</span>
																<p>Deposit Amount</p>
															</div>
															<Field
																type="number"
																placeholder={
																	!selectedFiat
																		? ''
																		: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																		  `Enter ${currencyLimits?.minimum.toLocaleString()} - ${currencyLimits?.maximum.toLocaleString(
																				'en-US',
																		  )}`
																}
																name="amount"
																onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
																	const value = e.target.value === '' ? '' : e.target.value || '0'; // Работаем со строками

																	// Расчет грязной суммы
																	const depositSummary = getCalcAmountDes(value, false);
																	console.log(depositSummary);
																	// eslint-disable-next-line @typescript-eslint/await-thenable
																	await setFieldValue('amount', value); // Грязная сумма как строка
																	// eslint-disable-next-line @typescript-eslint/await-thenable
																	await setFieldValue('depositSummary', depositSummary); // Чистая сумма (с учетом комиссий)
																}}
																onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
																	handleBlur(e);
																	validateField('amount');
																}}
																required
																disabled={!fiatCode}
																component={Input}
																addonRight={fiatCode}
															/>
														</div>

														<div className="wallet-operations-item wallet-operations-item--border-top">
															<div className="receive">
																<div className="wallet-operations-item__title">
																	<span>3</span>
																	<p>Deposit Summary</p>
																</div>
																<Field
																	type="number"
																	placeholder={
																		!selectedFiat
																			? ''
																			: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																			  `Enter ${currencyLimits?.minimum.toLocaleString()} - ${currencyLimits?.maximum.toLocaleString(
																					'en-US',
																			  )}`
																	}
																	name="depositSummary"
																	onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
																		// Получаем значение из поля ввода
																		const value = e.target.value === '' ? '' : e.target.value;

																		// Рассчитываем грязную сумму на основе чистой суммы
																		const amountValue = getCalcAmountDes(value, true); // Грязная сумма

																		// Обновляем поля
																		// eslint-disable-next-line @typescript-eslint/await-thenable
																		await setFieldValue('depositSummary', value); // Чистая сумма в формате строки
																		// eslint-disable-next-line @typescript-eslint/await-thenable
																		await setFieldValue('amount', amountValue); // Грязная сумма (без учета комиссий) в формате строки
																	}}
																	onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
																		handleBlur(e);
																		validateField('depositSummary');
																	}}
																	required
																	disabled={!fiatCode}
																	component={Input}
																	addonRight={fiatCode}
																/>
																{Number(values.depositSummary) < 0 && (
																	<div className="input__notification input__notification--type2 input__notification--ta-r">
																		<p>{notificationsInfoFields.amount.minZero}</p>
																	</div>
																)}
																<div className="receive-amount__fees">
																	<div className="receive-amount__fees-item">
																		<div className="receive-amount__fees-text">
																			Minimum Deposit:
																		</div>
																		<div className="receive-amount__fees-price">
																			{currencyLimits?.minimum.toLocaleString()}
																			<span>{fiatCode.toUpperCase()}</span>
																		</div>
																	</div>
																	<div className="receive-amount__fees-item">
																		<div className="receive-amount__fees-text">Deposit Fee:</div>
																		<div className="receive-amount__fees-price">
																			{values.amount ? (
																				<>
																					{roundingNumber(Number(fee), fiatCode)}
																					<span>{fiatCode.toUpperCase()}</span>
																				</>
																			) : (
																				<>
																					{0}
																					<span>{fiatCode.toUpperCase()}</span>
																				</>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top">
															<div className="wallet-operations-item__title">
																<span>4</span>
																<p>Reference</p>
															</div>
															<Field
																type="text"
																placeholder="Enter Reference"
																name="reference"
																required
																component={Input}
																className="text-field"
															/>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top">
															<div className="wallet-operations-item__title">
																<span>5</span>
																<p>Notes / Instructions</p>
															</div>

															<Field
																type="textarea"
																placeholder="Enter your text here"
																name="instructions"
																required
																component={Input}
																className="text-field"
															/>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top amount-input">
															<div className="wallet-operations-item__title">
																<span>6</span>
																<p>Upload Supporting Documentation</p>
															</div>
															<InputFile fileList={fileList} setFileList={setFileList} />
														</div>
														<div className="wallet-operations-item">
															<button
																type="submit"
																className="btn btn-primary btn--w-full"
																disabled={isSubmitting || !isAmountValid}
															>
																Confirm Deposit
															</button>
															{/* <button
																type="button"
																onClick={() => {
																	resetForm();
																	setResetCustomSelect(true);
																	setSelectedFiat(null);
																	setSubmitting(false);
																}}
																className="btn btn-danger btn--w-full btn--mt-10"
															>
																Cancel
															</button> */}
														</div>
													</>
												) : (
													<>
														{!!selectedCoin && (
															<div className="wallet-operations-item wallet-operations-item--border-top">
																<div className="wallet-operations-item__title">
																	<span>2</span>
																	<p>Select Network</p>
																</div>
																<div style={{ position: 'relative' }}>
																	<CurrencySelect
																		type="text"
																		dropdownTitle="Select Network"
																		placeholder="Select Network"
																		arr={
																			selectedCoin.code === 'btc'
																				? btcNetworksArr
																				: selectedCoin?.chains
																		}
																		onChange={setSelectedNetwork}
																		resetCustomSelect={resetCustomSelect}
																		setResetCustomSelect={setResetCustomSelect}
																		activeValue={selectedNetwork || undefined}
																	/>
																	<div className="error-block__wrap">
																		<div className="error-block">
																			<div className="error-block__icon" />
																			<p>
																				Ensure the deposit network is correct or assets will be
																				lost. If in doubt, send a small test first.
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														)}
														{(selectedCoin?.code === 'btc'
															? !!selectedNetwork
															: !!depositAddress) && (
															<>
																<div className="wallet-operations-item wallet-operations-item--border-top">
																	<div className="deposit-address">
																		<div className="wallet-operations-item__title">
																			<span>3</span>
																			<p>Deposit Address</p>
																		</div>
																		<div style={{ position: 'relative' }}>
																			<div className="deposit-address__text">
																				<p>
																					{selectedCoin?.code === 'btc'
																						? handleGetBtcAddress(selectedNetwork?.code || '')
																						: depositAddress?.address || ''}
																				</p>
																				<CopyToClipboard
																					text={
																						selectedCoin?.code === 'btc'
																							? handleGetBtcAddress(selectedNetwork?.code || '')
																							: depositAddress?.address || ''
																					}
																					onCopy={handleCopy}
																				>
																					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
																					<button type="button" />
																				</CopyToClipboard>
																			</div>
																			<div className="error-block__wrap">
																				<div className="error-block">
																					<div className="error-block__icon" />
																					<p>
																						If this is your first time depositing, please ensure you
																						send a small test transfer first.
																					</p>
																				</div>
																			</div>
																		</div>
																		<div className="receive-amount" style={{ marginBottom: 0 }}>
																			<div className="receive-amount__fees">
																				<div className="receive-amount__fees-item">
																					<div className="receive-amount__fees-text">
																						Minimum Deposit:
																					</div>
																					<div className="receive-amount__fees-price">
																						{roundingNumber(
																							currencyLimits?.minimum,
																							selectedCoin?.code,
																						)}
																						<span>{selectedCoin?.code.toUpperCase()}</span>
																					</div>
																				</div>
																				<div className="receive-amount__fees-item">
																					<div className="receive-amount__fees-text">
																						Deposit Fee:
																					</div>
																					<div className="receive-amount__fees-price">
																						{roundingNumber(
																							tradeInfo?.deposit_fee_fixed,
																							selectedCoin?.code,
																						)}
																						<span>{selectedCoin?.code.toUpperCase()}</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																{depositAddress?.tag && (
																	<div className="wallet-operations-item wallet-operations-item--border-top">
																		<div className="deposit-address">
																			<div className="wallet-operations-item__title">
																				<span>4</span>
																				<p>MEMO</p>
																			</div>
																			<div style={{ position: 'relative' }}>
																				<div className="deposit-address__text">
																					<p>{depositAddress.tag}</p>
																					<CopyToClipboard
																						text={depositAddress?.tag || ''}
																						onCopy={handleCopy}
																					>
																						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
																						<button type="button" />
																					</CopyToClipboard>
																				</div>
																				<div className="error-block__wrap">
																					<div className="error-block">
																						<div className="error-block__icon" />
																						<p>Memo is required or asset will be lost.</p>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																)}
															</>
														)}
													</>
												)}
											</>
										</Form>
									);
								}}
							</Formik>
							<InvoiceGenerate
								cleanFunc={cleanFunc}
								open={openModal}
								closeModal={closeModal}
								currencyCode={fiatCode}
								payloadData={payloadData}
								setOpenSuccessDepositPopUp={setOpenSuccessDepositPopUp}
							/>
							<SuccessDepositPopUp
								open={openSuccessDepositPopUp}
								closeModal={() => {
									closeSuccessDepositPopUp();
									closeModal();
								}}
							/>
						</div>
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default DepositFiatPage;
