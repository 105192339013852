import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const DeclineTransactionPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<div>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
				}}
			>
				<div className="popup">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
					<button className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title popup-header__title red">{title || 'Error'}</p>
					</div>
					<div className="popup-icon popup-icon--error">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="58"
							height="58"
							viewBox="0 0 58 58"
							fill="none"
						>
							<path
								d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
								fill="#FE0000"
								stroke="#FE0000"
								strokeOpacity="0.06"
								strokeWidth="16"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						>
							<path
								d="M11.9931 16.5292C12.1922 16.5292 12.3608 16.4618 12.4989 16.3272C12.637 16.1926 12.7061 16.0258 12.7061 15.8267C12.7061 15.6277 12.6388 15.4591 12.5041 15.321C12.3695 15.1829 12.2027 15.1138 12.0037 15.1138C11.8047 15.1138 11.6361 15.1811 11.4979 15.3157C11.3598 15.4504 11.2907 15.6172 11.2907 15.8162C11.2907 16.0152 11.3581 16.1838 11.4927 16.322C11.6273 16.4601 11.7941 16.5292 11.9931 16.5292ZM11.3484 13.2753H12.6484V7.27534H11.3484V13.2753ZM12.007 21.0984C10.7551 21.0984 9.57612 20.8616 8.46999 20.3879C7.36384 19.9142 6.39565 19.2625 5.56544 18.4326C4.7352 17.6028 4.08315 16.6354 3.60926 15.5305C3.13538 14.4255 2.89844 13.2449 2.89844 11.9886C2.89844 10.7323 3.13527 9.55529 3.60894 8.45749C4.0826 7.35967 4.73435 6.39566 5.56419 5.56544C6.39404 4.73521 7.36143 4.08315 8.46636 3.60926C9.5713 3.13538 10.7519 2.89844 12.0082 2.89844C13.2645 2.89844 14.4415 3.13527 15.5393 3.60894C16.6372 4.08261 17.6012 4.73435 18.4314 5.56419C19.2616 6.39404 19.9137 7.35948 20.3876 8.46051C20.8614 9.56155 21.0984 10.738 21.0984 11.9898C21.0984 13.2417 20.8616 14.4207 20.3879 15.5268C19.9142 16.633 19.2625 17.6012 18.4326 18.4314C17.6028 19.2616 16.6373 19.9137 15.5363 20.3876C14.4353 20.8614 13.2588 21.0984 12.007 21.0984ZM11.9984 19.7984C14.1651 19.7984 16.0067 19.0401 17.5234 17.5234C19.0401 16.0067 19.7984 14.1651 19.7984 11.9984C19.7984 9.83175 19.0401 7.99008 17.5234 6.47341C16.0067 4.95675 14.1651 4.19841 11.9984 4.19841C9.83175 4.19841 7.99008 4.95675 6.47341 6.47341C4.95675 7.99008 4.19841 9.83175 4.19841 11.9984C4.19841 14.1651 4.95675 16.0067 6.47341 17.5234C7.99008 19.0401 9.83175 19.7984 11.9984 19.7984Z"
								fill="white"
							/>
						</svg>
					</div>
					<div className="popup-body">
						{!!message && (
							<div className="popup-text popup-text--mt-24 popup-text--mb-8">
								<p>{message}</p>
							</div>
						)}
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--type2">
							<button type="button" className="btn btn-primary">
								Decline
							</button>
							<button
								onClick={() => {
									closeModal();
								}}
								type="button"
								className="btn btn-danger"
							>
								Go back
							</button>
						</div>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default DeclineTransactionPopUp;
