import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import IconSvg from '../../../ui/Svg/IconSvg';

interface Account {
	accountName: string;
	main: string;
	roles: string[];
	action: boolean;
}

// Define the props for TableBody component
interface IProps {
	item: Account;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<div className="td-hidden-name">Account Name</div>
					<div className="td__wrap">
						<div className="td__text">{item.accountName}</div>
						<div className="td__more">{item?.action ? 'Main' : ''}</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Roles</div>
					<div className="td__wrap">
						<div className="td__text">{item.roles.join(', ')}</div>
					</div>
				</div>
				<div className="td td-right">
					<div className="td-hidden-name">Action</div>
					<div className="td__wrap">
						{item.action ? (
							<>
								<div
									onClick={() => {
										history.push('/your-accounts/main');
									}}
									className="btn--icon"
								>
									<IconSvg name="eye" w="24" h="24" />
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
export default TableBody;
